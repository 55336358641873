.invoice-container{
    display: flex;
    flex-direction: column;
    min-width: 900px;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 90vh;
}

.invoice-footer p{
    margin-bottom: 8px;
}

@media print{
    body * {
        background-color: #fff;
        visibility: hidden;
        font: Georgia, "Times New Roman", Times, serif;
      }
      .invoice-content *{
        /* visibility: visible;
        margin: 0; */
        break-inside: avoid;
        page-break-inside: avoid;
      }
      /* .invoice-content h2 {
        margin-bottom: 1rem;
      }
      .invoice-content p {
        margin-bottom: 10px;
      } */
      /* .invoice-container * {
        width: 100%;
        visibility: visible;
        margin: 0;
        border: none;
        box-shadow: none;
      } */

      /* @page {
        size: auto !important;
        margin: 10mm 0 10mm 0 !important; 
      }
      .invoice-content{
        padding: 2rem !important;
      }
      .invoice-footer{
        margin-top: 1rem;
      } */
}