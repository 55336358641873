/* .container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.print-content {
  font-family: Arial, sans-serif;
}

.print-content h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.print-content p {
  font-size: 14px;
  margin-bottom: 5px;
}

.print-content strong {
  font-weight: bold;
}

button {
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media print {
  body {
    background-color: #fff;
    background-image: url('./assets/Pad.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .container {
    max-width: 100%;
    margin: 0;
    border: none;
  }
  .print-content {
    font-family: Arial, sans-serif;
    background-color: transparent;
  }
  button {
    display: none;
  }
}

 */
 .prescription-drawer  .ant-drawer-body{
    border: 1px solid #BDBDBD;
    display: flex;
    padding: 104px 0px 0px 0px;
    min-height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    background-color: #f5f5f5;
}
 .guest-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .guest-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .guest-header h1 {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .guest-header p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .print-content {
    font-family: Arial, sans-serif;
  }
  
  .print-content h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .print-content h4 p {
    margin-bottom: 10px;
  }

  
  .print-content strong {
    font-weight: bold;
  }
  
  .buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .guest-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  

  
  @media print {
    /* Hide unnecessary elements */
    body * {
      background-color: #fff; /* Set background color */
      visibility: hidden;
    }
    .print-content {
        visibility: visible;
    }
    .guest-container, .guest-container * {
      max-width: 100%;
      visibility: visible;
      margin: 0;
      border: none;
      box-shadow: none; 
    }
    .guest-container {
        position: absolute;
        left: 0;
        top: 0;
        page-break-inside: avoid;
      }

    .guest-button {
      display: none; /* Hide print button */
    }
    .ant-drawer-close{
        display: none;
    }
    .prescription-total{
      margin-top: 2rem;
    }
    .guest-notes{
      margin-top: 1rem;
    }
    .signature{
      position: absolute;
      bottom: 2rem;
      right: 1rem;
    }
    .signature h3{
      margin-bottom: 10px;
    }
    @page { size: auto;  margin: 0mm; }
  }
  
  