.ant-menu-item-selected{
    background-color: #1890ff !important;
    color: #fff !important;
    border-radius: 35px !important;
}
li{
    margin-bottom: 10px !important;
}
.ant-layout-header{
    height: 80px !important;
}
.switch-theme{
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    overflow: hidden;
    transition: all 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s;
}
