:root {
    --primary-color: #1890ff;
  }

  .radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .radio-tile-group .ant-radio-button-wrapper {
    width: 10rem;
    background-color: transparent;
   
  }
  
  .radio-tile-group .ant-radio-button-wrapper input {
    position: absolute;
    opacity: 0;
  }
  
  .radio-tile-group .ant-radio-button-wrapper .ant-radio-button {
    /* border-radius: 30px !important; */
    transition: all 300ms ease;
  }
  

  
  .radio-tile-group .ant-radio-button-wrapper .ant-radio-button .label {
    color: var(--primary-color);
    font-size: 0.60rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  
  .radio-tile-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked .ant-radio-button {
    background-color: var(--primary-color);
  }
  

  
  .radio-tile-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked .ant-radio-button .ionicon,
  .radio-tile-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked .ant-radio-button .label {
    color: white !important;
  }
  .radio-tile-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked #account-payment-label {
  color: #fff !important;
 }
 .radio-tile-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked #account-payment-icon {
  color: #fff !important;
 }
 #account-payment-icon{
  margin-left: 2px !important;
 }
 
/* .account-payment .ant-radio-button-wrapper .ant-radio-button{
  border-radius: 32px !important;
}
   */

.radio-tile-group .ant-radio-button-wrapper{
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;

}

.radio-tile-group .ant-radio-button-wrapper:first-child{
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;

}