.lab-pres-container {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.lab-info h4 {
  margin-bottom: 8px;
}
.lab-info p {
  margin-bottom: 8px;
}
.lab-info p span {
  font-weight: 500;
}

.lab-details p {
  margin-bottom: 8px;
}

.lab-details p span {
  font-weight: 500;
}

@media print {
    body * {
      background-color: #fff;
      visibility: hidden;
      font: Georgia, "Times New Roman", Times, serif;
    }
    .lab-content *{
      visibility: visible;
      margin: 0;
    }

    .lab-pres-container * {
        width: 100%;
        visibility: visible;
        margin: 0;
        border: none;
        box-shadow: none;
      }

      @page {
        size: auto !important;
        margin: 20mm 0 10mm 0 !important; 
      }

      .lab-content{
        padding: 3rem !important;
      }

      .print-divider{
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
}