@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

 /* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */

 .watermark-drawer {
    position: relative;
  }
  
  .watermark-content {
    position: relative;
    z-index: 1;
  }
  
  html, body {
    scroll-behavior: smooth; /* You can use smooth scrolling */
  }

  .watermark-drawer::after {
    content: "Paid";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    color: rgba(0, 0, 0, 0.1); 
    pointer-events: none; 
    z-index: 0; 
  }
  .ant-table-cell .ant-table-selection-column{
    width: "5%" !important;
  }

  .ql-editor > p{
    font-size: 14px !important;
    line-height: 1.8rem !important;
  }