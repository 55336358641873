/* .calender {
    padding: 50px;
  } */
  .appointment_box{
    display: flex;
  }
  .appointment_box > .left {
    width: 35%;
    display: flex;
    flex-direction: column;
    background-color: #3384ef;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
  }
  .appointment-form{
    width: 65%;
  }
  .appointment_box > .left >  p, h5 {
    color: white;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;
  }

  .appointment_box > .left > p{
    font-size: 13px;
  }

  .right-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
  }
  .right-header >  p{
    font-size: 16px;
    font-weight: 500;
  }
  .appointment_modal > .ant-modal-content {
    padding: 0 !important;
  }
  .appointment-form{
    padding: 20px 24px;
  }
  /* .ant-form-item {
    margin-bottom: 12px !important;
  } */
  
  .tooltip__icon{
    margin-left: 5px;
    color: #3384ef;
  }
  .fc-button-primary {
    margin-right: 8px !important;
    background-color: #3384ef !important;
    border: none !important;
    border-radius: 8px;
  }
  .fc-event-time{
    display: none !important;
  }
  .fc-list-event-time{
    display: none !important;
  }