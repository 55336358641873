.prescription-container {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.pres-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4;
    margin-top: 4;
}
.print-divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.clinic-info p{
  margin-bottom: 8px;
}
.print-pres-content{
    box-sizing: border-box;
    font-family: Arial;
    height: 100%;
    line-height: 1.15;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
}
.prescription-details p{
  margin-bottom: 8px;
}
.provider-info h4{
  margin-bottom: 8px;
}
.provider-info p{
  margin-bottom: 8px;
}
.patient-info h4{
  margin-bottom: 8px;
}
.patient-info p{
  margin-bottom: 8px;
}
.patient-info p span{
  font-weight: 500;
}
.clinic-info h4{
  margin-bottom: 8px;
}
.clinic-info p span{
  font-weight: 500;
}
.pharmacy-info h4{
  margin-bottom: 8px;
}
.pharmacy-info p{
  margin-bottom: 8px;
}
.pharmacy-info p span{
  font-weight: 500;
}

@media print {
  body * {
    background-color: #fff;
    visibility: hidden;
    font: Georgia, "Times New Roman", Times, serif;
  }
  .print-pres-content *{
    visibility: visible;
    margin: 0;
  }
  .prescription-container * {
    width: 100%;
    visibility: visible;
    margin: 0;
    border: none;
    box-shadow: none;
  }


  @page {
    size: auto !important;
    margin: 10mm 0 10mm 0 !important; 
  }

  
  .print-pres-content{
    padding: 3rem !important;
  }
  .prescription-details p{
    margin-bottom: 8px;
    font-size: 12pt;
  }

  .clinic-info p{
    margin-bottom: 8px;
    font-size: 12pt;
  }
  .pharmacy-info h4{
    margin-bottom: 8px;
  }
  .pharmacy-info p{
    margin-bottom: 8px;
    font-size: 12pt;
  }
  .patient-info h4{
    margin-bottom: 8px;
  }
  .patient-info p{
    margin-bottom: 8px;
    font-size: 12pt;
  }

  .print-divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .provider-info{
    margin-top: 1rem;
  }
  .provider-info h4{
    margin-top: 1rem;
  }
  .provider-info p{
    margin-bottom: 8px;
  }

  .prescription-container {
    font-size: 12pt;
  }
}
