.referral-report-container{
    display: flex;
    flex-direction: column;
    min-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 90vh;
}

@media print{
    body * {
        background-color: #fff;
        visibility: hidden;
        font: Georgia, "Times New Roman", Times, serif;
      }
      .referral-report-content *{
        visibility: visible;
        margin: 0;
      }
      .referral-report-content h2 {
        margin-bottom: 1rem;
      }
      .referral-report-content p {
        margin-bottom: 10px;
      }
      .referral-report-container * {
        width: 100%;
        visibility: visible;
        margin: 0;
        border: none;
        box-shadow: none;
      }

      @page {
        size: auto !important;
        margin: 10mm 0 10mm 0 !important; 
      }
      .referral-report-content {
        padding: 2rem !important;
      }
      .referral-divider{
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
      }
}