
.container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .input-container {
    position: relative;
    height: 7rem;
    width: 15rem;
    margin: 4rem;
  }
  
  .input-container input {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
  }
  
  .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 2px solid #1677ff;
    border-radius: 8px;
    transition: all 300ms ease;
  }
  
  .input-container svg {
    color: #1677ff;
    font-size: 3rem;
  }
  
  .input-container label {
    color: #1677ff;
    font-size: 0.80rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  input:checked+.radio-tile {
    background-color: #1677ff;
    box-shadow: 0 0 12px var(--primary-color);
    transform: scale(1.1);
  }
  
  input:hover+.radio-tile {
    box-shadow: 0 0 12px var(--primary-color);
  }
  
  input:checked+.radio-tile svg,
  input:checked+.radio-tile label {
    color: white;
  }
.tags-class{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 5px 8px;
    border-radius: 5px;
    background-color: #1677ff;
    color: white;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
}
.ant-tag-close-icon{
  color: white !important;
}



.ant-collapse-header{
  background-color: #1890ff !important;
  color: white !important;
  border-radius: 8px 8px 0 0 !important; 
}