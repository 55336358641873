*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'montserrat', sans-serif !important;
  list-style: none;
  /* overflow-x: hidden; */
}
.ant-btn-primary {
  background-color: #1890ff !important;
}
.ant-btn-primary:hover {
  background-color: #40a9ff !important;
}
.danger__button{
  background-color: #F44336 !important;
  border: none;
  color: white !important;
}
.danger__button:hover{
  background-color: #eb3629 !important;
  border: none;
  color: white !important;
}
.ant-drawer-header-title{
display: flex !important;
flex-direction: row-reverse;
}

.ant-menu-item:hover{
  border-radius: 35px;
}

::-webkit-scrollbar{
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track{
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb{
  background-color: rgb(190, 190, 190);
}

.shadow-class{
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.07); 
  /* box-shadow: 0px 4px 30px rgba(221,224,255,.54);; */
  /* box-shadow: 0px 4px 30px rgba(221,224,255,.54); */
 }
 .ant-form-item-label>label{
  color: #01509A !important;
  font-size: 14px !important;
 }
 
 .anticon {
  min-width: 14px;
  /* margin-right: 4px; */
  font-size: 15px !important;
}

.ant-table-tbody > tr:nth-child(even) {
  border-radius: 8px 0px 0px 8px !important;
  background-color: #fafafa !important;
}

.ant-table-body > table > tbody > tr > td {
  border-radius: 5px !important;
}
.ant-form-item-tooltip > svg {
  color: #01509A !important;
  font-size: 13px !important;
  margin-left: 3px !important;
}
.upload__icon{
  font-size: 40px !important;
}

.ant-tabs-tab-btn{
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing:  0.2px !important;
}

/* CustomModal.css */
.custom-modal .ant-modal-content {
  padding: 0 0 24px 0 !important;
}
.custom-modal .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

}

.custom-modal .ant-btn {
  margin-right: 6px; 
}

.custom-modal .ant-btn-dangerous {
  background-color: #F44336 !important;
  border: none;
  color: white !important;
}
.custom-modal .ant-btn-dangerous:hover{
  background-color: #eb3629 !important;
  border: none;
  color: white !important;
}

.custom-modal .ant-modal-confirm-body {
  justify-content: center;
}
.custom-modal .ant-modal-confirm-title{
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #F44336;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #fff !important;
}

.custom-modal .ant-modal-confirm-content{
  text-align: center;
}

.custom-modal-checkout-warning .ant-modal-confirm-title{
  text-align: center;
}

.custom-modal-checkout-warning .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

}

.custom-modal-checkout-warning .ant-btn {
  margin-right: 6px; 
}


.summary-menu .ant-menu-item-selected{
  background-color: transparent !important;
  color:  #1890ff !important;
  font-weight: bold;
}

.summary-menu .ant-layout {
  background-color: transparent !important;
}

.jodit-status-bar-link{
  display: none !important;
}

.jodit-ui-button_insert_as_text{
  display: none !important;
}


.emr-breadcrumb > ol > li > span > a {
  color: #343a40 !important;
}

.change-password-modal > .ant-modal-close-x> .ant-modal-close-icon{
  display: none !important;
}

.waiting-room-button{
  background-color: #22c55e  !important;
  color: white !important;
  border: none !important;

  &:hover {
    background-color: #16a34a; /* Dark green color on hover */
  }
}

.app-close-icon{
  background-color: #9ca3af !important;
  color: white !important;
  margin-right: 2.5rem;
  &:hover{
    color: white !important;
    background-color: #7f7f7f !important;
    border: none !important;
  }
}

.drawer-close-icon{
  background-color: #9ca3af !important;
  color: white !important;
  &:hover{
    color: white !important;
    background-color: #7f7f7f !important;
    border: none !important;
  }
}

/* Lab Results */
.lab-results-modal {
  .lab-details {
    margin-bottom: 20px;

    .ant-typography {
      margin-bottom: 10px;
    }
  }

  .pdf-container {
    text-align: center;
    margin-bottom: 20px;
  }

  .pdf-label {
    margin-top: 10px;
    display: block;
    color: #666;
  }

  .image-preview {
    max-width: 100%;
  }

  .toolbar-wrapper {
    justify-content: center;
  }

  .view-file-button {
    margin-top: 20px;
  }
}


/* staff availability */
.staff-item {
  border-bottom: 1px solid #f0f0f0;
}

.name {
  font-weight: bold;
}

.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.role {
  margin-bottom: 0;
}

.time {
  margin-bottom: 0;
}

.note {
  margin-bottom: 0;
  color: #888;
}

.status {
  margin-bottom: 0;
}


.staff-schedule p{
  margin-bottom: 8px;
}

.disabled-textarea-view{
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
  padding: 0 !important; 
}