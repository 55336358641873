.prescription-container {
    padding: 20px;
  }
  
  .checkbox-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .checkbox-group .ant-checkbox-wrapper {
    margin-bottom: 10px;
  }
  
  .data-section {
    margin-top: 20px;
  }
  
  .data-card {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .data-card p {
    margin: 5px 0;
    color: #333;
  }
  
  .data-card p span {
    font-weight: bold;
  }
  
  .no-data {
    color: #999;
    text-align: center;
    margin-top: 20px;
  }
  
  .facesheet-header p{
    padding-bottom: 10px;
  }

  .facesheet-card {
    border: 1px solid gray !important;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }