.profile-wrapper{
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}
.image-wrapper {
    width: 6.9375rem;
    /* margin: 0 auto 1.25rem auto; */
    margin-right: 10px;
    border-radius: 50%;

}

.ant-avatar > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.wrapper {
  padding: 50px 60px;
}

.profile-nav-wrapper{
  display: flex;
  flex-direction: column;
  padding: 20px 60px;
}

.profile-nav-wrapper .active {
  background-color: #1890ff !important;
  color: white;
}
.profile-nav-wrapper .active:hover {
  background-color: #40a9ff !important;
  color: white;
}
.view-wrapper p{
  margin-bottom: 8px;
}
.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word;
}


.payment-method {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.payment-method .ant-radio-button-wrapper {
  width: 10rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.payment-method .ant-radio-button-wrapper input {
  position: absolute;
  opacity: 0;
}

.payment-method .ant-radio-button-wrapper .ant-radio-button {
  border-radius: 8px;
  transition: all 300ms ease;
}



.payment-method .ant-radio-button-wrapper .ant-radio-button .label {
  color: var(--primary-color);
  font-size: 0.80rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.payment-method .ant-radio-button-wrapper.ant-radio-button-wrapper-checked .ant-radio-button {
  background-color: var(--primary-color);
}



.radio-tile-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked .ant-radio-button .ionicon,
.radio-tile-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked .ant-radio-button .label {
  color: white;
}

.notes{
  /* white-space: pre-wrap;  Preserves newlines and wraps long text */
  word-wrap: break-word;  /* Ensures long words break to fit the container */
  max-width: 100%; 
}