.membership-card {
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .membership-card:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eee;
  }
  
  .card-actions {
    display: flex;
    gap: 8px;
  }
  
  .action-icon {
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
  

  
  .status-badge {
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    text-transform: uppercase;
  }
  
  .status-badge.active {
    background-color: #1890ff;
    color: white;
  }
  
  .status-badge.inactive {
    background-color: #f5222d;
    color: white;
  }
  
  .claim-button{
    border-radius: 15px !important;
    border: none;
  }
  .claim-button:hover, 
.claim-button:focus {
    color: #000 !important;
    border-radius: 15px !important;
    border: none !important;
}