.custom-label{
    color: #1677ff !important;
    font-size: 0.80rem;
    /* letter-spacing: 1px; */
}
.ant-form-item-label > label[name='username']{
    color: #1677ff !important;
    font-size: 0.80rem;
}

.social-icons {
    font-size: 26px !important;
}